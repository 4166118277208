import React, { useState } from 'react'
import leftMenu from '../../Images/leftMenu.png'
import closeMenu from '../../Images/closeMenu.png'
import Banner from '../../Images/Banner.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import './Header.scss';
import AreaData from '../../Components/ServicesPage/AreaData'
import Arrow from '../../Images/downArrow.png'

export default function Header({ showMenu, setShowMenu, navigateTo, WebSitePages }) {
    const [header, setHeader] = useState(false)

    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">

            <div className="HeaderTopComputer">
                <div className="Header2" >
                    <img src={Banner} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA" />
                    <a href="/">AIR DUCT Ventura, CA</a>
                </div>

                <div className="Header1">
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/">Home</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <h1>Services</h1>
                            <img src={Arrow} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA" />
                        </div>

                        <div className="Header1BoxMenu">
                            {AreaData.map((A, index) =>
                                <a href={A.Path} className="Header1BoxMenuBox">
                                    {A.TitleShort}
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/contact">Contact Us</a>
                        </div>
                    </div>
                    <div className="Header1Box">
                        <div className="Header1BoxHedaer">
                            <a href="/blog">Blog</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="HeaderTop">
                <div className="Header2" >
                    <img src={Banner} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA" />
                    <a href="/">AIR DUCT Ventura, CA</a>
                </div>
                <div className='Header3'>
                    {showMenu ?
                        <img
                            style={header ? { "filter": "invert(0)", "width": "30px" } : { "filter": "invert(1)", "width": "30px" }}
                            src={closeMenu} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA"
                            className='menuCloseBtn'
                            onClick={() => setShowMenu(false)}
                        />
                        :
                        <img
                            style={header ? { "filter": "invert(0)" } : { "filter": "invert(1)" }}
                            src={leftMenu} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA"
                            onClick={() => setShowMenu(true)}
                        />
                    }

                </div>
            </div>


            <div className="HeaderBottom">
                <div className='Header2'>
                    <div className="Header2Box" onClick={() => navigateTo("contact")}>
                        <h2>CONTACT US</h2>
                    </div>
                    <div className="Header2Box" onClick={() => navigateTo("blog")}>
                        <h2 >BLOG</h2>
                    </div>
                </div>
                <div className="Header1Main">
                    <div className='Header1'>
                        <a href="/book">SCHEDULE ONLINE<img src={book} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA" /></a>
                    </div>
                    <div className='Header1'>
                        <a href="tel:805-678-0270">CALL 805-678-0270<img src={telephone} alt="Air Duct Cleaning In Ventura, CA | Air Duct Maintenance In Ventura, CA | AC Duct Cleaning In Ventura, CA | Dryer Vent Cleaning In Ventura, CA" /></a>
                    </div>
                </div>
            </div>

        </div>
    )
}
